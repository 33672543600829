
import { ref } from "vue"
import { localGet, localSet } from "@/assets/script/utils/storage.util"
import { COUNT_VIEW_MODEL_KEY } from "@/assets/script/constant/config.constant"
import { ExpoConfigViewModel } from "@/assets/script/model/main.model"
import { Toast } from "vant"
import { ExhibitionService } from "@/assets/script/service/exhibition.service"

export default {
  setup() {
    const viewModel = ref(
      localGet(COUNT_VIEW_MODEL_KEY, new ExpoConfigViewModel())
    )
    const save = () => {
      localSet(COUNT_VIEW_MODEL_KEY, viewModel.value)
      const toast = Toast.success({
        message: "保存成功"
      })
    }
    const flash = () => {
      ExhibitionService.flashWeightData(
        viewModel.value.bedId,
        viewModel.value.mainBoardId
      )
    }
    const openRealtime = () => {
      ExhibitionService.openRealtime(
        viewModel.value.bedId,
        viewModel.value.mainBoardId
      )
    }
    return {
      viewModel,
      save,
      flash,
      openRealtime
    }
  }
}
